import React from "react";
import { Router } from "@reach/router";
//import Landing from "./index";
import About from './about';
import Offer from './offer';
import Thanks from './thanks';
import Error404 from "./404";
import Error500 from "./500";
import TermsAndCondition from "./terms"
import Landing from "./landing";
import SamsungLanding from "./samsunglanding";

const App = () => {
  return (
    
       <Router basepath="/">
        <Landing path="/?TradeID={tradeId}" />
        <SamsungLanding path="/samsung" />
        <About path="/about" />
        <Offer path="/offer" />
        <Thanks path="/thanks" />
        <TermsAndCondition path="/terms" />
        <Error500 path="/500" />
        <Error404 path="/*" />
      </Router>
    
  )
}

export default App